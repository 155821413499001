import { defineNuxtPlugin } from '#imports'
      import { plugin, defaultConfig, ssrComplete } from '@formkit/vue'
      import { resetCount } from '@formkit/core'

      import importedConfig from '/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/109/nuxt/public-portal/formkit.config.ts'

      export default defineNuxtPlugin((nuxtApp) => {
        const config = defaultConfig(typeof importedConfig === 'function' ? importedConfig() : importedConfig)
        nuxtApp.hook('app:rendered', (renderContext) => {
          resetCount()
          ssrComplete(nuxtApp.vueApp)
        })
        nuxtApp.vueApp.use(plugin, config)

      })
      