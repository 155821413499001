import { default as _91_46_46_46page_93bJjqBZ7vKBMeta } from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/109/nuxt/public-portal/pages/[...page].vue?macro=true";
import { default as bedanktwDvGl2Mg4FMeta } from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/109/nuxt/public-portal/pages/bedankt.vue?macro=true";
import { default as indexNaSpYmKlsIMeta } from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/109/nuxt/public-portal/pages/index.vue?macro=true";
import { default as wifi_45connectjQ4pjNs8d7Meta } from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/109/nuxt/public-portal/pages/wifi-connect.vue?macro=true";
export default [
  {
    name: _91_46_46_46page_93bJjqBZ7vKBMeta?.name ?? "page",
    path: _91_46_46_46page_93bJjqBZ7vKBMeta?.path ?? "/:page(.*)*",
    meta: _91_46_46_46page_93bJjqBZ7vKBMeta || {},
    alias: _91_46_46_46page_93bJjqBZ7vKBMeta?.alias || [],
    redirect: _91_46_46_46page_93bJjqBZ7vKBMeta?.redirect,
    component: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/109/nuxt/public-portal/pages/[...page].vue").then(m => m.default || m)
  },
  {
    name: bedanktwDvGl2Mg4FMeta?.name ?? "bedankt",
    path: bedanktwDvGl2Mg4FMeta?.path ?? "/bedankt",
    meta: bedanktwDvGl2Mg4FMeta || {},
    alias: bedanktwDvGl2Mg4FMeta?.alias || [],
    redirect: bedanktwDvGl2Mg4FMeta?.redirect,
    component: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/109/nuxt/public-portal/pages/bedankt.vue").then(m => m.default || m)
  },
  {
    name: indexNaSpYmKlsIMeta?.name ?? "index",
    path: indexNaSpYmKlsIMeta?.path ?? "/",
    meta: indexNaSpYmKlsIMeta || {},
    alias: indexNaSpYmKlsIMeta?.alias || [],
    redirect: indexNaSpYmKlsIMeta?.redirect,
    component: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/109/nuxt/public-portal/pages/index.vue").then(m => m.default || m)
  },
  {
    name: wifi_45connectjQ4pjNs8d7Meta?.name ?? "wifi-connect",
    path: wifi_45connectjQ4pjNs8d7Meta?.path ?? "/wifi-connect",
    meta: wifi_45connectjQ4pjNs8d7Meta || {},
    alias: wifi_45connectjQ4pjNs8d7Meta?.alias || [],
    redirect: wifi_45connectjQ4pjNs8d7Meta?.redirect,
    component: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/109/nuxt/public-portal/pages/wifi-connect.vue").then(m => m.default || m)
  }
]